.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contentPane {
  flex-grow: 1;
  transition: opacity 350ms ease-in-out;
  transition-delay: 150ms;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 15rem;
  overflow: hidden scroll;
  background-color: var(--secondary-top-bar-color);
}

.pathContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  white-space: pre;
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.btnContainer {
  width: 50%;
  margin-left: auto;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  .btnContainer {
    width: auto;
    margin-left: unset;
    flex-direction: row;
  }
}

.pathTitle {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.info {
  font-size: 12px;
  color: black;
}

.modalDeleteIconContainer {
  display: flex;
  margin-left: auto;
}

.shareInputContainer {
  display: flex;
  gap: 0.5rem;

  .userInput {
    width: 70%;
  }

  .roleInput {
    flex-grow: 1;
  }
}

.manageCollabContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid #eeeeee;
  background: white;

  &:hover {
    background: #eeeeee;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
  }

  .collabText {
    width: 60%;

  }

  .collabInput {
    margin-left: auto;
    background: #d0d0d0;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    min-width: 4rem;
    text-align: center;
  }

  .roleEdit {
    &:hover {
      background-color: #d0d0d0;
      border-radius: 50%;
    }
  }
}

.collabBox {
  max-height: 15rem;
  overflow-y: scroll;
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.disabled {
  pointer-events: none;
  opacity: 0;
}
