.listItem {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: var(--secondary-top-bar-color);
  gap: 0.5rem;
  font-size: 14px;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0;

  &:not(:last-child) {
    border-bottom: 1px solid #d0d0d0;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:hover {
    background-color: var(--accent-color);
  }
}

.noInteraction {
  &:hover {
    background-color: var(--secondary-top-bar-color) !important;
    cursor: default !important;
  }
}

.icon {
  height: 1rem;
  width: 1rem;
}

.triangleIcon {
  @extend .icon;
  cursor: pointer;
}

.hideIcon {
  opacity: 0;
  pointer-events: none;
}

.selectedItem {
  background-color: var(--accent-color);
}

.menuIcon {
  margin-left: auto;
  cursor: pointer;
  z-index: 20;
  position: relative;
}

.activeSettings {
  position: relative;

  .settingsMenu {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    position: absolute;
    background: white;
    border-radius: var(--border-radius);
    top: -8px;
    right: calc(100% + 24px);
    border: var(--border);
    box-shadow: var(--box-shadow);
    white-space: nowrap;
    font-size: 12px;
    min-width: 5rem;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 6.5px;
      right: -18px;
      margin: auto;
      border-width: 9px;
      border-style: solid;
      border-color: transparent transparent transparent #d5d5d5;
      z-index: 10;
    }

    * {
      padding: 0.5rem 0.5rem;
      min-width: 2.5rem;
      color: black;

      &:hover {
        cursor: pointer;
        color: black;
      }
    }
  }
}

.line {
  height: 1px;
  background: #d0d0d0;
  position: relative;
  padding: 0 !important;
  width: calc(100% - 1rem);
  left: 0.5rem;
}

.menuContainer {
  margin-left: auto;
  display: flex;
  position: relative;

  &::after {
    position: absolute;
    content: " ";
    background: var(--secondary-color);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    top: -0.5rem;
    right: -0.5rem;
    opacity: 0;
    mask: radial-gradient(circle,
        rgba(0, 0, 0, 1) 0,
        rgba(0, 0, 0, 0) 100%) 100% 50% / 100% 100%;
  }

  &:hover {
    &::after {
      opacity: 0.25;
    }
  }

  &:active {
    .menuIcon {
      scale: 0.85;
    }
  }
}

.menuItem {
  cursor: pointer;
  border: 0;
  background-color: white;
  text-align: left;

  &:hover {
    background-color: var(--accent-color);
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.editText {
  padding: 0;
  border: 0;

  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
