.header {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
}

.content {
  font-size: 14px;
  line-height: 1.5;
  color: #000;
}

.errorContent {
  font-size: 12px;
  line-height: 1.7;
  color: red;
}

.infoContent {
  font-size: 12px;
  line-height: 1.7;
  color: var(--primary-color);
}
